import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledMenuIcon = styled.div`
  height: 100%;
  top: 33%;

  width: 40px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  margin: auto;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2), span:nth-child(3) {
    top: 10px;
  }

  span:nth-child(4) {
    top: 20px;
  }

  ${props => (!props.isOpen
    ? ''
    : `
    span:nth-child(1) {
      top: 10px;
      width: 0%;
      left: 50%;
    }

    span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: 10px;
      width: 0%;
      left: 50%;
    }
  `
  )}
`

const MenuIcon = (props) => {
  return (
    <StyledMenuIcon onClick={() => props.handleMenuIconClick(null)} isOpen={props.menuState}>
      <span />
      <span />
      <span />
      <span />
    </StyledMenuIcon>
  )
}

MenuIcon.propTypes = {
  handleMenuIconClick: PropTypes.func.isRequired,
  menuState: PropTypes.bool.isRequired
}

export default MenuIcon;
