import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import PropTypes from 'prop-types';
import { getBackgroundImageByPageName } from '../utils/imageHelper';
import { videoStuff } from '../utils/contentProvider';

const StyledContainer = getBackgroundImageByPageName('About');

const ImageDiv = styled.div`
  margin-left: auto; margin-right: auto;
  border-radius: 50%;
  border: 3px solid black;
  background: url(${props => props.src}) no-repeat center #fff;
  background-size: 100% 100%;
  height: 150px;
  width: 150px;

  @media screen and (max-height: 768px) {
    height: 110px;
    width: 110px;
  }

  
  @media screen and (max-height: 550px) {
    height: 80px;
    width: 80px;
  }
`

const ImageDesc = styled.div`
  text-align: center;
  width: 80%;
  margin: auto;
  padding: 0;
`

const SmallImageDesc = styled.div`
  width: 100%;
  font-size: 16px;

  @media screen and (max-height: 450px), (max-width: 450px) {
    font-size: 12px;
  }
`

const ContentBackgroundDiv = styled.div`
  align-self: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  margin: 70px 0 0 0;
  padding: 50px 0;

  @media screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
  }

  @media screen and (max-height: 768px) {
    margin: 0;
    padding: 15px 0;
  }

  @media screen and (max-height: 450px) {
    margin: 0;
    padding: 0;
    height: 100%;
  }
`

const RowDiv = styled.div`
  margin: 0;
  width: 100%;
  padding: 15px 0;

  @media screen and (max-height: 500px) {
    padding: 10px 0;
  }
`

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;

  @media screen and (max-height: 400px) {
    padding: 60px 0;
  }

  @media screen and (max-height: 360px) {
    padding: 65px 0;
  }

  @media screen and (max-height: 320px) {
    padding: 65px 0;
  }
  
`

  
const AboutPage = () => {
  return (
    <Container fluid style={{ width: '100%', padding: 0 }}>
      <StyledContainer>
        <Hidden xs sm md lg>
          <ContentBackgroundDiv>
            <Row align="normal" justify="center" style={{ margin: 0 }}>
              {videoStuff.map(img => (
                <Col key={img.name}>
                  <ImageDiv key={img.name} src={img.src} />
                  <ImageDesc>
                    <div>
                      <h3>{img.name}</h3>
                    </div>
                    <div>
                      {img.description}
                    </div>
                  </ImageDesc>
                </Col>
              ))}
            </Row>
          </ContentBackgroundDiv>
        </Hidden>
        <Visible xs sm md lg>
          <ContentBackgroundDiv style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <ContentDiv>
              {videoStuff.map((img, index) => (
                <RowDiv key={img.name}>
                  <Row style={{ margin: 0 }} justify="center" nowrap align="center" >
                    <Col xs="content" style={ index % 2 === 0 ? { order: 0 } : { order: 1 } }>
                      <ImageDiv key={img.name} src={img.src} />
                    </Col>
                    <Col style={ index % 2 === 0 ? { textAlign: 'left' } : { textAlign: 'right' } }>
                      <SmallImageDesc>
                        <div>
                          <h3 style={{ marginTop: 0, marginBottom: '0.5rem' }}>{img.name}</h3>
                        </div>
                        <div>
                          {img.description}
                        </div>
                      </SmallImageDesc>
                    </Col>
                  </Row>
                </RowDiv>
              ))}
            </ContentDiv>
          </ContentBackgroundDiv>
        </Visible>
      </StyledContainer>
    </Container>
  )
}

AboutPage.propTypes = {
  isActive: PropTypes.bool.isRequired
}

export default AboutPage;
