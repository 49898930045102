import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-scroll';
import styled from 'styled-components';

import logo from '../../static/images/logo.png'

const StyledSideMenu = styled.div`
  height: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
`;

const StyledNavLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
 `;
  
const NavLinkStyle = {
  padding: 'auto',
  textTransform: 'uppercase',
  textDecoration: 'none',
  color: 'white',
  display: 'block',
  minHeight: '50px',
  width: '100%',
}

const LinkLabel = styled.p`
  width: 100%;
  top: 50%;
  text-align: center;
`

const LogoStyle = {
  width: 240,
  height: 60
}


const SideMenu = (props) => {
  return (
    <StyledSideMenu style={ props.isOpen ? { width: '80%' } : { width: 0 } }>
      <div style={{ textAlign: 'center' }}>
        <Link to={props.topPage.name} spy smooth duration={700} onClick={() => props.handleClick(props.topPage)}>
          <img src={logo} alt="" style={LogoStyle}/>
        </Link>
      </div>
      <StyledNavLinksContainer>
        {
          props.pages.map((page) => (
            <Link key={page.id} to={page.name} spy smooth duration={700} style={NavLinkStyle} onClick={() => props.handleClick(page)}>
              <LinkLabel>
                {page.text}
              </LinkLabel>
            </Link>
          ))
        }
      </StyledNavLinksContainer>
    </StyledSideMenu>
  )
}

SideMenu.propTypes = {
  topPage: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired
}

export default SideMenu;
