import { thumbnails } from './imageHelper';

export const videos = [
  { name: 'Ada & Mateusz', src: 'https://www.youtube-nocookie.com/embed/dq38Pz0tz9Y', thumbnail: thumbnails[0] },
  { name: 'Magdalena & Davide', src: 'https://www.youtube-nocookie.com/embed/ZzU_p1fGKpE', thumbnail: thumbnails[1] },
  { name: 'Ewa Maria Gawęda', src: 'https://www.youtube-nocookie.com/embed/CMhnZ2TBV9s', thumbnail: thumbnails[2] },
  { name: 'Daniela & Maciej', src: 'https://www.youtube-nocookie.com/embed/f0WQDjjxTY0', thumbnail: thumbnails[3] },
  { name: 'Faustyna & Rafał', src: 'https://www.youtube-nocookie.com/embed/1a8Pe91WpiI', thumbnail: thumbnails[4] },
  { name: 'Filologia włoska UJ', src: 'https://www.youtube-nocookie.com/embed/x86eYtWtfz8', thumbnail: thumbnails[5] },
]
