import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { getBackgroundImageByPageName } from '../utils/imageHelper';

const StyledContainer = getBackgroundImageByPageName('Home');

const RowStyle = {
  alignSelf: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  width: '100%', 
  margin: 0,
  height: 200,
  textAlign: 'center',
}

const StyledH1 = styled.h1`
  @media screen and (max-width: 650px), (max-height: 500px) {
    font-size: 24px;
  }
`

const HomePage = () => {
  return (
    <Container fluid style={{ width: '100%', padding: 0 }}>
      <StyledContainer>
        <Row align="center" justify="center" style={RowStyle} >
          <Col>
            <StyledH1>
              Tworzymy indywidualne, pełne emocji filmy
            </StyledH1>
          </Col>
        </Row>
      </StyledContainer>
    </Container>
  )
}

export default HomePage;
