import BackgroundImageDiv from '../components/shared/BackgroundImage';

import HomeBackground from '../static/background/home.jpg';
import AboutBackground from '../static/background/about.jpg';
import GalleryBackground from '../static/background/gallery.jpg';
import OffersBackground from '../static/background/offers.jpg';
import ContactBackground from '../static/background/contact.jpg';

import vgLogo from '../static/images/logo.png'

import roninSrc from '../static/images/ronin.jpeg';
import cameraSrc from '../static/images/camera_2.jpeg';
import dronSrc from '../static/images/dron.jpeg';

import offersImg from '../static/images/offers.jpeg';
import fbBlack from '../static/images/fb_black_100.png';
import fbBlue from '../static/images/fb_blue_100.png';

import thumbnail_0 from '../static/thumbnails/vid_0.jpg';
import thumbnail_1 from '../static/thumbnails/vid_1.jpg';
import thumbnail_2 from '../static/thumbnails/vid_2.jpg';
import thumbnail_3 from '../static/thumbnails/vid_3.jpg';
import thumbnail_4 from '../static/thumbnails/vid_4.jpg';
import thumbnail_5 from '../static/thumbnails/vid_5.jpg';

export const logo = vgLogo;
export const OffersImg = offersImg;
export const videoStuffImages = { roninSrc, cameraSrc, dronSrc }

export const fbLogos = {
  fbBlack,
  fbBlue
}

export const thumbnails = [
  thumbnail_0,
  thumbnail_1,
  thumbnail_2,
  thumbnail_3,
  thumbnail_4,
  thumbnail_5
]


export const getBackgroundImageByPageName = (pageName) => {
  switch (pageName) {
  case 'Home':
    return BackgroundImageDiv(HomeBackground);
  case 'About':
    return BackgroundImageDiv(AboutBackground);
  case 'Gallery':
    return BackgroundImageDiv(GalleryBackground); 
  case 'Offers':
    return BackgroundImageDiv(OffersBackground);
  case 'Contact':
    return BackgroundImageDiv(ContactBackground);
  default:
    return;
  }
}

