import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { logo, fbLogos } from '../../utils/imageHelper';
import MenuIcon from './MenuIcon';
import SideMenu from './SideMenu';

const ContainerStyle = {
  background: 'rgba(0, 0, 0, 0.4)',
  padding: '0 15px',
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 1,
  minHeight: 50
}

const ContainerDiv = styled.div`
  width: 100%;
  height: 10vh;

  @media (max-height: 850px) {
    height: 7vh;
  }

  max-height: 90px;
`

const StyledNavLinksContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  max-width: 100%;
  height: 100%;
  min-height: 50px;
`

const NavLinkDiv = styled.div`
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: white;
  width: 150px;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  
  :hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
`

const Logo = styled.img`
  display: block;
  height: 100%;
  min-width: 200px;
  min-height: 50px;
  max-width: 360px;
  max-height: 90px;

  :hover {
    cursor: pointer;
  }
`

const preventDefault = (e) => {
  e = e || window.event;
  if (e.preventDefault)
    e.preventDefault();
  e.returnValue = false;  
}

const FacebookDiv = styled.div`
    :hover {
      cursor: pointer;
    }

    div {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin: 0 15px;
      background: url(${fbLogos.fbBlue}) no-repeat center;
      background-size: 100% 100%;
    }    
`

const openFbProfile = () => {
  window.open('https://www.facebook.com/vgstudios.movies', '_blank');
}

const Header = (props) => {
  const [ menuState, setMenuState ] = useState(false);
  
  const handleMenuIconClick = (page) => {
    if (page !== null) props.handlePageChange(page);
    setMenuState(!menuState);
    
    if (!menuState) {
      document.addEventListener('wheel', preventDefault, { passive: false });
      document.addEventListener('touchmove', preventDefault, { passive: false });
    } else {
      document.removeEventListener('wheel', preventDefault, { passive: false });
      document.removeEventListener('touchmove', preventDefault, { passive: false });
    }
  }

  return (
    <Container fluid style={ContainerStyle}>
      <ContainerDiv>
        <Visible xs sm md>
          <SideMenu isOpen={menuState} topPage={props.topPage} pages={props.pages} handleClick={handleMenuIconClick} handlePageChange={props.handlePageChange} />
        </Visible>
        <Row justify="between" style={{ height: '100%', flexWrap: 'nowrap' }}>
          <Col xs="content" style={{ height: '100%' }}>
            <Link to={props.topPage.name} spy smooth duration={700} onClick={() => props.handlePageChange(props.topPage)}>
              <Logo src={logo} alt="" />
            </Link>
          </Col>
          <Col xs="content" style={{ height: '100%' }}>
            <Hidden xs sm md>
              <StyledNavLinksContainer>
                {
                  props.pages.map((page) => (
                    <Link key={page.id} to={page.name} spy smooth duration={700} onClick={() => props.handlePageChange(page)} style={{ height: '70%' }}>
                      <NavLinkDiv>
                        {page.text}
                      </NavLinkDiv>
                    </Link>
                  ))
                }
                <FacebookDiv onClick={() => openFbProfile()}><div /></FacebookDiv>
              </StyledNavLinksContainer>
            </Hidden>
            <Visible xs sm md>
              <MenuIcon handleMenuIconClick={handleMenuIconClick} menuState={menuState} style={{ height: '100%' }} />
            </Visible>
          </Col>
        </Row>
      </ContainerDiv>
    </Container>
  );
}

Header.propTypes = {
  topPage: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  handlePageChange: PropTypes.func
}

export default Header;
