import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { getBackgroundImageByPageName } from '../utils/imageHelper';
import { OffersDetails } from '../utils/contentProvider';

const StyledContainer = getBackgroundImageByPageName('Offers');

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  width: 100%;
  height: 100%;
  margin: 0 0 0 0;
  padding: 15px 0;

  @media screen and (max-height: 800px), screen and (max-width: 450px) {
    padding: 60px 0;
    height: 80%;
    font-size: 0.8em;
  }
`

const OfferDiv = styled.div`
  background-color: rgba(255, 255, 255, 0.7);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto; margin-right: auto;

  width: 350px;
  height: 100%;
  padding: 0 10px;

  h1 {
    color: #207d57;
  }

  @media screen and (max-height: 800px), screen and (max-width: 450px) {
    width: 300px;
  }

  @media screen and (max-width: 800px) {
    margin: 0 auto;
    padding: 0 15px;
    width: 250px;
  }

  @media screen and (max-width: 450px) {
    width: 250px;
    margin-left: auto; margin-right: auto;
    font-size: 0.9em;
    padding: 0 15px;
  }
`

const OfferDetailsDiv = styled.div`
  height: 100%;
  margin: auto;
  text-align: center;

  width: 70%;
  padding: 0 0 20px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    padding: 0;
  }
`

const OfferDetailTitle = styled.h3`
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
`

const OfferDetailDesc = styled.p`
  font-size: 0.8em;
  margin: 0 0 30px 0;

  @media screen and (max-width: 800px) {
    margin: 0 0 10px 0;
  }
`

const OffersPage = () => {
  return (
    <Container fluid style={{ padding: 0, margin: 0 }}>
      <StyledContainer>
        <ContentDiv>
          <Row style={{ margin: 0, width: '100%' }} justify="center">
            <Col xs="content" style={{ margin: '10px 0' }}>
              <OfferDiv>
                <h1>STANDARD</h1>
                <OfferDetailsDiv>
                  {OffersDetails.standard.map(detail => (
                    <div key={detail.title}>
                      <OfferDetailTitle>{detail.title}</OfferDetailTitle>
                      <OfferDetailDesc>{detail.desc}</OfferDetailDesc>
                    </div>
                  ))}
                </OfferDetailsDiv>
              </OfferDiv>
            </Col>
            <Col xs="content" style={{ margin: '10px 0' }}>
              <OfferDiv>
                <h1>PREMIUM</h1>
                <OfferDetailsDiv>
                  {OffersDetails.premium.map(detail => (
                    <div key={detail.title}>
                      <OfferDetailTitle>{detail.title}</OfferDetailTitle>
                      <OfferDetailDesc>{detail.desc}</OfferDetailDesc>
                    </div>
                  ))}
                </OfferDetailsDiv>
              </OfferDiv>
            </Col>
          </Row>
        </ContentDiv>
      </StyledContainer>
    </Container> 
  );
}

export default OffersPage;
