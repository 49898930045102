import React from 'react';
import { Container, Row, Col, Visible, Hidden, ScreenClassRender } from 'react-grid-system';
import styled from 'styled-components';
import { getBackgroundImageByPageName } from '../utils/imageHelper';
import { videos } from '../utils/videoHelper';

const StyledContainer = getBackgroundImageByPageName('Gallery');

const ContentBackgroundDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 15px 0;
`

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  max-width: 560px;
  max-height: 315px;
  min-height: 270px;
`


const GalleryPage = () => {
  return (
    <Container fluid style={{ width: '100%', padding: 0, margin: 0 }}>
      <Visible xl>
        <StyledContainer>
          <ContentBackgroundDiv>
            <Row align="normal" justify="center" style={{ margin: 0, alignSelf: 'center', width: '90%' }}>
              {videos.map(vid => (
                <Col key={vid.name} xl={4} style={{ textAlign: 'center', padding: '10px' }}>
                  <StyledIframe src={vid.src} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </Col>
              ))}
            </Row>
          </ContentBackgroundDiv>
        </StyledContainer>
      </Visible>
      <Hidden xl>
        <ScreenClassRender render={screenClass => (
          <div style={{ backgroundColor: 'rgba(255, 255, 255)', width: '100%', padding: '60px 0', minHeight: '100vh' }}>
            <Row align="normal" justify="center" style={{ width: ['md'].includes(screenClass) ? '80%' : '90%', marginLeft: 'auto', marginRight: 'auto' }}>
              {videos.map(vid => (
                <Col key={vid.name} lg={6} style={{ minHeight: 270, textAlign: 'center', padding: '10px' }}>
                  <StyledIframe src={vid.src} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </Col>
              ))}
            </Row>
          </div>
        )} />
      </Hidden>
    </Container>
  )
}

export default GalleryPage;
