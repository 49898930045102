import React from 'react';
import PageContainer from './PageContainer'

function App() {
  return (
    <React.Fragment>
      <PageContainer />
    </React.Fragment>
  );
}

export default App;
