import { videoStuffImages } from './imageHelper';

export const OffersDetails = {
  standard: [
    { title: 'Film ślubny', desc: 'około 60min*' },
    { title: 'Sesja plenerowa', desc: 'krótki plener w dniu ślubu' },
    { title: 'Operator x 1', desc: 'ujęcia wykonywane przez 1 operatora' },
    { title: 'Pendrive ślubny x 1', desc: 'film ślubny nagrany na pendrive' }
  ],
  premium: [
    { title: 'Zwiastun filmu', desc: '1-3 minutowa zapowiedź filmu' },
    { title: 'Film ślubny', desc: 'około 60min*' },
    { title: 'Sesja plenerowa', desc: 'krótki plener w dniu ślubu' },
    { title: 'Operator x 2', desc: 'ujęcia wykonywane przez 2 operatorów' },
    { title: 'Pendrive ślubny x 3', desc: 'zwiastun filmu oraz film ślubny nagrany w 3 kompletach' },
    { title: 'Ujęcia z drona gratis**', desc: '' }
  ],
  comments: [
    { id: 1, text: '* możliwość dopasowania długości filmu do indywidualnych preferencji' },
    { id: 2, text: '** w zależności od warunków atmosferycznych i strefy przestrzeni powietrznej' }
  ]
}

export const videoStuff = [
  {
    id: 0,
    name: 'Stabilne i dynamiczne filmy',
    src: videoStuffImages.roninSrc,
    description: 'W naszej pracy wykorzystujemy różne urządzenia stabilizujące, takie jak: ronin, monopod, statyw, slider'
  }, {
    id: 1,
    name: 'Piękne kadry w najwyższej jakości',
    src: videoStuffImages.cameraSrc,
    description: 'Nasze pełnoklatkowe bezlusterkowce w połączeniu z obiektywami o wysokiej jasności pozwalają na tworzenie pięknych i artystycznych ujęć o wysokiej głębi ostrości'
  }, {
    id: 2,
    name: 'Ujęcia z lotu ptaka',
    src: videoStuffImages.dronSrc,
    description: 'Wykorzystujemy ujęcia z drona, aby wzbogacić film o piękne widoki z całkowicie innej perspektywy'
  }
];
