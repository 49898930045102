import styled from 'styled-components';

const BackgroundImageDiv = (image) => {
  return styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: url(${image});

    background-repeat: no-repeat;
    background-position: center;
	
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  `;
}

export default BackgroundImageDiv;
