import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { getBackgroundImageByPageName, fbLogos } from '../utils/imageHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faHome, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const StyledContainer = getBackgroundImageByPageName('Contact');

const ContentDiv = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 21px;
  padding: 70px 100px;
  max-width: 100%;

  @media screen and (max-width: 500px) {
    padding: 20px 0;
  }

  @media screen and (max-height: 600px) {
    padding: 20px 0;
    font-size: 16px;
  }
`

const iconStyle = {
  width: '30px',
  height: 'auto'
}

const StyledContactRow = styled.div`
  margin: 40px 20px;

  transition: color 0.3s ease-in-out;
  
  :hover {
    color: #207d57;
    cursor: default;
  }

  @media screen and (max-height: 650px), (max-width: 500px) {
    margin: 30px 20px;
  }
`

const FacebookDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 40px 20px;
    padding: 5px 15px;
    margin-left: auto; margin-right: auto;

    font-size: 15px;
    color: #1877f2;
    text-align: center;
    border: 2px solid #1877f2;
    border-radius: 50px;
    width: 80%;

    transition: background-color 0.7s ease-out;

    :hover {
      background-color: #fff;
      cursor: pointer;
    }

    div.fbLogo {
      display: inline-block;
      width: 50px;
      height: 50px;
      min-width: 50px;
      min-height: 50px;
      background: url(${fbLogos.fbBlue}) no-repeat center;
      background-size: 100% 100%;
    }    
    
    
    @media screen and (max-height: 650px), (max-width: 500px) {
      margin: 30px auto;
      font-size: 12px;
      width: 50%;

      div.fbLogo {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
      }
    }
`

const openFbProfile = () => {
  window.open('https://www.facebook.com/vgstudios.movies', '_blank');
}

const ContactPage = () => {
  return (
    <Container fluid style={{ width: '100%', padding: 0, margin: 0 }}>
      <StyledContainer>
        <ContentDiv>
          <StyledContactRow>
            <Row justify="center" align="center" style={{ margin: 0 }}>
              <Col xs="content">
                <FontAwesomeIcon icon={faPhone} style={iconStyle} />
              </Col>
              <Col>
                <div>+48 691 396 900</div>
              </Col>
            </Row>
          </StyledContactRow>
          <StyledContactRow>
            <Row justify="center" align="center" style={{ margin: 0 }}>
              <Col xs="content">
                <FontAwesomeIcon icon={faEnvelope} style={iconStyle} />
              </Col>
              <Col>
                <div>info@vgstudios.pl</div>
              </Col>
            </Row>
          </StyledContactRow>
          <StyledContactRow>
            <Row justify="center" align="center" style={{ margin: 0 }}>
              <Col xs="content">
                <FontAwesomeIcon icon={faHome} style={iconStyle} />
              </Col>
              <Col>
                <div>ul. Pszowska 531</div>
                <div>44-370 Pszów</div>
              </Col>
            </Row>
          </StyledContactRow>
          <Row>
            <Col>
              <FacebookDiv onClick={() => openFbProfile()}>
                <div className="fbLogo" />
                <div>
                  Znajdź nas na Facebooku
                </div>
              </FacebookDiv>
            </Col>
          </Row>
        </ContentDiv>
      </StyledContainer>
    </Container>
  )
}

export default ContactPage;
