import React, { useState, useEffect } from 'react';
import { Events, Element, scroller } from 'react-scroll';

import Header from '../components/shared/Header';
import HomePage from '../components/HomePage';
import AboutPage from '../components/AboutPage';
import GalleryPage from '../components/GalleryPage';
import OffersPage from '../components/OffersPage';
import ContactPage from '../components/ContactPage';


const topPage = {
  id: 0,
  name: 'homePage'
}

const pagesToMenu = [
  { id: 1, name: 'aboutPage', text: 'O Nas' },
  { id: 2, name: 'galleryPage', text: 'Galeria' },
  { id: 3, name: 'offersPage', text: 'Oferta' },
  { id: 4, name: 'contactPage', text: 'Kontakt' },
]

const allPages = [
  topPage,
  ...pagesToMenu
]

const PageContainer = () => {
  const [ activePage, setActivePage ] = useState(topPage);

  useEffect(() => {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
    
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    }
  }, []);
  
  useEffect(() => {    
    // let touchStart;
    // let touchEnd;

    // const handleTouchStart = (event) => {
    //   touchStart = event.touches[0].clientY;
    // }

    const handleMove = (event) => {
      let direction = 0;
      
      if (event instanceof WheelEvent)
        event.deltaY < 0 ? direction = 1 : direction = -1;
      
      else if (event instanceof KeyboardEvent) {
        if (event.keyCode === 38) direction = 1;
        else if (event.keyCode === 40) direction = -1;
      }
      
      // else if (event instanceof TouchEvent) {
      //   touchEnd = event.changedTouches[0].clientY;
        
      //   Math.abs(touchStart - touchEnd) < 10
      //     ? direction = 0
      //     : touchStart < touchEnd ? direction = 1 : direction = -1;
      // }
      
      const scrollTo = allPages.find(x => (x.id + direction) === activePage.id);
      
      if (scrollTo !== undefined) {
        scroller.scrollTo(scrollTo.name, {
          duration: 700,
          smooth: true,
          spy: true
        });

        setActivePage(scrollTo);
      }
    }
    
    document.onwheel = handleMove;
    document.onkeydown = handleMove;
    // document.ontouchstart = handleTouchStart;
    // document.ontouchend = handleMove;
  }, [activePage]);
  
  return (
    <React.Fragment>
      <div>
        <Header topPage={topPage} pages={pagesToMenu} handlePageChange={setActivePage} />
        <Element name="homePage">
          <HomePage isActive={activePage.name === 'homePage'} />
        </Element>
        <Element name="aboutPage">
          <AboutPage isActive={activePage.name === 'aboutPage'} />
        </Element>
        <Element name="galleryPage">
          <GalleryPage isActive={activePage.name === 'galleryPage'} />
        </Element>
        <Element name="offersPage">
          <OffersPage isActive={activePage.name === 'offersPage'} />
        </Element>
        <Element name="contactPage">
          <ContactPage isActive={activePage.name === 'contactPage'} />
        </Element>
      </div>
    </React.Fragment>
  );
}

export default PageContainer;
